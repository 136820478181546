import Parse from "parse"
import moment from "moment"

const BASE_URL = "https://smile-inn-server.herokuapp.com"

//Initialize Parse
Parse.initialize(
  "ChQH95GPP7uzpKuwb4MD3cqArxM6C1gZHle5xMPG",
  "nrPldUM1wDZWI0n2E4rOPuaKXYaeppCFz7eDPrEl"
)
Parse.serverURL = "https://parseapi.back4app.com"

//USERS
export async function CurrentUserAsync() {
  // Parse.User.logOut()
  let currentUser = await Parse.User.currentAsync()

  if (currentUser == null) {
    return null
  }

  //Add the auth token to the current user OBJ
  currentUser = {
    id: currentUser.id,
    fullName: currentUser.get("fullName"),
    email: currentUser.get("email"),
  }
  return currentUser
}
export async function Register() {
  var user = new Parse.User()

  user.set("fullName", "Smile Inn Member")
  user.set("username", "member@smileinntt.com")
  user.set("password", "SmileInn5109")
  user.set("email", "member@smileinntt.com")

  try {
    await user.signUp()
    // Hooray! Let them use the app now.
  } catch (error) {
    // Show the error message somewhere and let the user try again.
    alert("UhOh: " + error.message)
  }
}

export async function LoginUser(email, password) {
  var val = undefined

  try {
    const user = await Parse.User.logIn(email, password)
    val = user
  } catch (error) {
    alert("Uh-Oh: " + error.message)
  }

  return val
}

export async function getTimesForDay(date, location) {
  const query = new Parse.Query("Appointments")

  let start = moment(date).startOf("day")

  let endDate = moment(date)
    .endOf("day")
    .toDate()

  query.greaterThanOrEqualTo("date", start.toDate())
  query.lessThan("date", endDate)
  query.equalTo("location", location)

  try {
    const results = await query.find()

    let appointments = []

    results.map(appointment => {
      let appointmentObj = {
        id: appointment.id,
        date: moment(appointment.get("date")).toISOString(),
        confirmed: appointment.get("confirmed"),
        paid: appointment.get("paid"),
        booked: appointment.get("booked"),
      }

      appointments.push(appointmentObj)
    })

    return appointments
  } catch (err) {
    alert("Something went wrong retrieving the times for this day")
  }
}

export async function getAllAppointments() {
  const query = new Parse.Query("Appointments")

  const results = await query.find()

  let appointments = []

  results.map(appointment => {
    let appointmentObj = {
      id: appointment.id,
      title: `${appointment.get("fullName")} - ${appointment.get(
        "serviceType"
      )}`,
      serviceType: appointment.get("serviceType"),
      fullName: appointment.get("fullName"),
      start: appointment.get("date"),
      end: moment(appointment.get("date"))
        .add(1, "hour")
        .toDate(),
      confirmed: appointment.get("confirmed"),
      paid: appointment.get("paid"),
      note: appointment.get("note"),
    }

    appointments.push(appointmentObj)
  })

  return appointments
}

export async function addAppointment(
  start,
  fullName,
  emailAddress,
  phoneNumber,
  note,
  serviceType,
  selectedLocation
) {
  const Appointments = Parse.Object.extend("Appointments")
  const appointment = new Appointments()

  var returnVal

  appointment.set("date", start)
  appointment.set("fullName", fullName)
  appointment.set("emailAddress", emailAddress)
  appointment.set("phoneNumber", phoneNumber)
  appointment.set("note", note)
  appointment.set("serviceType", serviceType)
  appointment.set("location", selectedLocation)

  try {
    const savedAppointment = await appointment.save()
    console.log(`Appointment made with id: ${savedAppointment.id}`)
    return {
      success: true,
      appointment: savedAppointment,
    }
  } catch (err) {
    return {
      success: false,
      error: err.message,
    }
  }

  await appointment.save().then(
    savedEvent => {
      console.log(`Saved event with id: ${savedEvent.id}`)
      returnVal = 200
      // axios
      //   .post(`${BASE_URL}/send-email`, {
      //     name: fullName,
      //     email: emailAddress,
      //     phone: phoneNumber,
      //     service: serviceType,
      //     note: note,
      //     date: moment(start).format("dddd MMM Do [at] h:mm a"),
      //   })
      //   .then(res => {})
      //   .catch(err => {
      //     console.log(err)
      //   })
    },
    err => {
      console.log(`Something went wrong, ${err.message}`)
      returnVal = err
    }
  )

  if (returnVal === 200) {
    return true
  } else {
    return returnVal.message
  }
}

export async function addReview(reviewData) {
  const Reviews = Parse.Object.extend("Reviews")
  const review = new Reviews()

  const { name, rating, comment } = reviewData

  review.set("name", name)
  review.set("rating", rating)
  review.set("comment", comment)

  try {
    const savedReview = await review.save()
    console.log(`Review saved with id: ${savedReview.id}`)
    return {
      success: true,
      review: savedReview,
    }
  } catch (err) {
    console.log(err.message)
    return {
      success: false,
      error: err.message,
    }
  }
}

export async function getAllReviews() {
  const query = new Parse.Query("Reviews")
  query.descending("createdAt")

  const results = await query.find()

  let reviews = []

  results.map(review => {
    let reviewObj = {
      id: review.id,
      createdAt: review.createdAt,
      name: review.get("name"),
      rating: review.get("rating"),
      comment: review.get("comment"),
    }

    reviews.push(reviewObj)
  })

  return reviews
}

export async function patientRegister(formData) {
  const Patient = Parse.Object.extend("Patients")
  const patientRegister = new Patient()

  try {
    const savedPatientRegister = await patientRegister.save(formData)
    console.log(`Patient saved with id: ${savedPatientRegister.id}`)
    return {
      success: true,
      patient: savedPatientRegister,
    }
  } catch (err) {
    console.log(err.message)
    return {
      success: false,
      error: err.message,
    }
  }
}

export async function updatePatientData(formData, id) {
  const Patient = Parse.Object.extend("Patients")
  const query = new Parse.Query(Patient)

  try {
    const patient = await query.get(id)
    const savedPatient = await patient.save(formData)
    const patientData = {
      ...savedPatient.attributes,
      id: savedPatient.id,
      createdAt: savedPatient.createdAt,
    }
    return {
      success: true,
      patient: patientData,
    }
  } catch (error) {
    console.log(error.message)
    return {
      success: false,
      error: error.message,
    }
  }
}

export async function getPatientHistory() {
  const query = new Parse.Query("Patients")
  query.descending("createdAt")

  const results = await query.find()

  let historyData = []
  results.map(patient => {
    const patientData = {
      ...patient.attributes,
      id: patient.id,
      createdAt: patient.createdAt,
    }
    historyData.push(patientData)
  })

  return historyData
}
